import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
  Injectable,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { NbSecurityModule, NbRoleProvider } from "@nebular/security";
import { of as observableOf, Observable } from "rxjs";
import {
  NbPasswordAuthStrategy,
  NbAuthModule,
  NbAuthJWTToken,
  NbAuthService,
} from "@nebular/auth";

import { throwIfAlreadyLoaded } from "./module-import-guard";
import {
  // AnalyticsService,
  LayoutService,
  // PlayerService,
  // SeoService,
  // StateService,
} from "./utils";
import { map } from "rxjs/operators";

const socialLinks = [
  {
    url: "https://github.com/akveo/nebular",
    target: "_blank",
    icon: "github",
  },
  {
    url: "https://www.facebook.com/akveo/",
    target: "_blank",
    icon: "facebook",
  },
  {
    url: "https://twitter.com/akveo_inc",
    target: "_blank",
    icon: "twitter",
  },
];

@Injectable({
  providedIn: "root",
})
export class NbSimpleRoleProvider extends NbRoleProvider {
  constructor(private authService: NbAuthService) {
    super();
  }

  getRole(): Observable<string> {
    return this.authService.onTokenChange().pipe(
      map((token: NbAuthJWTToken) => {
        return token.isValid() ? token.getPayload()["role"] : "trader";
      })
    );
  }
}

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: "email",
        token: {
          class: NbAuthJWTToken,
          key: "token",
        },
        baseEndpoint: "https://api.app.adrpoint.gr/api",
        // baseEndpoint: "https://devapi.app.adrpoint.gr/api",
        login: {
          endpoint: "/auth/login",
          method: "post",
          redirect: {
            success: "/home/",
            failure: null, // stay on the same page
          },
        },
      }),
    ],
    forms: {
      login: {
        socialLinks: [],
        rememberMe: false,
        // socialLinks: socialLinks,
      },
      register: {
        socialLinks: socialLinks,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      trader: {
        view: ["conversations", "statistics", "changePassword", "subExpiration", "evaluation"]
      },
      mediator: {
        view: ["cases", "mediatorCase", "conversations"],
        edit: ["cases", "mediatorCase"],
      },
      admin: {
        view: "*",
        create: "*",
        remove: "*",
      },
      mediationBody: {
        view: [
          "cases",
          "mediatorCase",
          "mediators",
          "conversations",
          "traders",
          "statistics",
        ],
        edit: ["cases", "mediatorCase"],
        // create: "*",
        // remove: "*",
      },
    },
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },
  // AnalyticsService,
  LayoutService,
  // PlayerService,
  // SeoService,
  // StateService,
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
